import $ from "jquery";
import store from "@/state/store.js";
import moment from "moment";
import _ from "lodash";
import Storage from "@/services/storage.js";
import userMultiAction from "./userMultiAction.vue";
export default {
  name: "",
  components: {
    userMultiAction
  },
  props: {
    parent: {
      type: Object,
      required: true
    },
    theoBotMode: {
      type: Boolean,
      default: false
    },
    dateTimeTheoBot: {
      type: Array,
      default: () => [],
      required: false
    }
  },
  data() {
    return {
      dataPayload: {},
      from: "",
      search: "",
      actionModalPayload: {},
      page: 1,
      pageSize: 50,
      total: 0,
      search: "",
      receivedData: [],
      tableLoading: false,
      autoApprovedTableCount: 0,
      showReserveID: false,
      reserveIdData: {},
      buttonLoading: false,
      multiSelectActive: false,
      multiSelectedData: []
    };
  },
  methods: {
    handleCurrentChange(val) {
      this.tableLoading = true;
      store.dispatch("senderid/adminSenderIDs", {
        page: val,
        status: this.parent.status
      }).then(data => {
        this.receivedData = data.data;
        if (this.parent.from == "autoApproved") {
          this.receivedData = this.receivedData.filter(item => {
            return item.approved_by == "SYSTEM";
          });
          return;
        }
        this.page = val;
      }).finally(() => {
        this.tableLoading = false;
      });
    },
    openActionModal() {
      let element = this.$refs.actionModal.$el;
      $(element).modal("show");
    },
    closeModal() {
      let element = this.$refs.actionModal.$el;
      $(element).modal("hide");
    },
    approve(message) {
      this.actionModalPayload = {
        message: "Are you sure you want to approve this sender name?",
        actionMessage: "Approve",
        data: message.row,
        action: "senderID",
        status: 1,
        senderType: this.parent.from,
        index: message.$index
      };
      this.openActionModal();
    },
    disapprove(message) {
      this.actionModalPayload = {
        message: "Are you sure you want to decline this sender name?",
        actionMessage: "Decline",
        data: message.row,
        status: 2,
        action: "senderID",
        senderType: this.parent.from,
        index: message.$index
      };
      this.openActionModal();
    },
    monitor(message) {
      this.actionModalPayload = {
        message: "Are you sure you want to monitor this sender name?",
        actionMessage: "Monitor",
        data: message.row,
        status: 3,
        senderType: this.parent.from,
        action: "senderID",
        index: message.$index
      };
      this.openActionModal();
    },
    statusText(status) {
      if (status == "1") return "Approved";
      if (status == "0") return "Pending";
      if (status == "2") return "Declined";
      if (status == "3") return "On Hold";
    },
    statusTextClasss(status) {
      return [status == "1" && "approved" || status == "0" && "pending" || status == "2" && "declined" || status == "3" && "monitor"];
    },
    searchSenderIDs: _.debounce(function () {
      if (this.search !== "") {
        this.tableLoading = true;
        let status = this.parent.status == "Pending" ? 0 : this.parent.status == "Approved" ? 1 : this.parent.status == "Declined" ? 2 : this.parent.status == "On_Hold" ? 3 : "";
        let payload = {
          status: status,
          search: this.search
        };
        store.dispatch("senderid/searchSenderIdAdmin", payload).then(data => {
          this.receivedData = data.data.data;
          this.total = data.data.data.length;
        }).catch(() => {
          this.$message({
            type: "error",
            message: "There was an issue searching for this Sender ID"
          });
        }).finally(() => {
          this.tableLoading = false;
        });
      }
      // Hit your endpoint here using this.searchTerm as the search query.
    }, 700),
    reserve(scope) {
      this.showReserveID = true;
      this.reserveIdData = {
        ...scope.row
      };
    },
    submitAction() {
      let createBy = new Storage();
      let payload = {
        sender_id: this.reserveIdData.sender_id,
        user_id: this.reserveIdData.user_id,
        created_by: createBy.getSavedState("auth.admin").user_id
      };
      return store.dispatch("senderid/addReservedSenderIDs", payload).then(() => {
        this.showReserveID = false;
        this.$message({
          type: "success",
          message: "Sender ID successfully Reserved"
        });
        store.dispatch("senderid/adminSenderIDs", payload).then(() => {
          this.fetchSenderIDsFromStore();
        });
      }).catch(() => {
        this.$message({
          type: "error",
          message: "There was an issue reserving this Sender ID"
        });
      });
    },
    fetchSenderIDsFromStore() {
      this.tableLoading = true;
      if (this.$props.theoBotMode) {
        store.dispatch("admin/reporting/fetchTheoBotSenderIDs", {
          page: this.page,
          status: this.parent.status,
          date: this.$props.dateTimeTheoBot
        }).then(data => {
          this.receivedData = data.data;
          this.total = data.total;
          this.autoApprovedTableCount = data.count_auto_approved;
        }).finally(() => {
          this.tableLoading = false;
        });
        return;
      }
      // Fetch sender ids from admin normally
      store.dispatch("senderid/adminSenderIDs", {
        page: this.page,
        status: this.parent.status
      }).then(data => {
        this.receivedData = data.data;
        this.total = data.total;
        this.autoApprovedTableCount = data.count_auto_approved;
      }).finally(() => {
        this.tableLoading = false;
      });
    },
    handleSelectionChange(val) {
      if (val.length == 0) {
        this.multiSelectActive = false;
        return;
      }
      this.multiSelectActive = true;
      this.multiSelectedData = val;
    },
    cancelMultiSelect($event) {
      this.multiSelectActive = false;
      this.multiSelectedData = [];
      this.$refs.senderIDTable.clearSelection();
      if ($event) {
        this.fetchSenderIDsFromStore();
      }
    }
  },
  computed: {
    searchingTableData() {
      if (!this.search) {
        this.total = this.tableData.length;
        return this.tableData;
      }
      this.page = 1;
      return this.tableData.filter(data => data.name && data.name.toLowerCase().includes(this.search.toLowerCase()) || data.reservedIDs.toLowerCase().includes(this.search.toLowerCase()));
    },
    tableData() {
      return store.getters["senderid/" + this.parent.storeDataName];
    },
    loading() {
      return store.getters["senderid/loading"];
    },
    showApprovedButton() {
      return this.$route.path == "/admin/sender_ids/approved" ? false : true;
    },
    showAutoApprovedButton() {
      return this.$route.path == "/admin/sender_ids/autoApproved" ? false : true;
    },
    showDeclineButton() {
      return this.$route.path == "/admin/sender_ids/declined" ? false : true;
    },
    showPendingUserVerification() {
      return this.$route.path == "/admin/sender_ids/pendingUserVerification" ? false : true;
    },
    showReserveButton() {
      return this.$route.path == "/admin/sender_ids/ReservedSenderIDs" ? false : true;
    },
    displayData() {
      if (this.parent.from == "autoApproved") {
        this.total = this.autoApprovedTableCount;
        this.receivedData = this.receivedData.filter(item => {
          return item.approved_by == "SYSTEM";
        });
        return this.receivedData;
      }
      return this.receivedData;
    }
  },
  mounted() {
    this.tableLoading = true;
    if (this.$props.theoBotMode) {
      return;
    }
    store.dispatch("senderid/adminSenderIDs", {
      page: 1,
      status: this.parent.status
    }).then(data => {
      this.receivedData = data.data;
      this.total = data.total;
      this.page = 1;
      this.autoApprovedTableCount = data.count_auto_approved;
    }).finally(() => {
      this.tableLoading = false;
    });
  },
  watch: {
    search: function (val) {
      if (val == "") {
        this.tableLoading = true;
        store.dispatch("senderid/adminSenderIDs", {
          page: 1,
          status: this.parent.status
        }).then(data => {
          this.receivedData = data.data;
          this.total = data.total;
          this.page = 1;
          this.autoApprovedTableCount = data.count_auto_approved;
        }).finally(() => {
          this.tableLoading = false;
        });
      }
    },
    multiSelectActive: function (val) {
      if (val == false) {}
    }
  },
  watch: {
    dateTimeTheoBot: {
      handler() {
        this.fetchSenderIDsFromStore();
      }
    }
  }
};